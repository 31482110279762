<!-- Localized -->
<template>
  <div
    class="case-creation-flow h-full flex flex-col max-h-full overflow-hidden flex-grow"
  >
    <div v-if="isLoading" class="p-10 flex items-center justify-center w-full">
      <Loader />
    </div>
    <template v-else>
      <div v-if="!addEntityWorkflow && (!selectedPackage || packageLoaders[selectedPackageId])" 
        class="flex overflow-hidden h-full w-full">
        <PackageList
          :loading="loading"
          :list="packageList"
          :packageLoaders="packageLoaders"
          @select="selectPackage($event.id)"
        />
      </div>
      <div v-else class="flex flex-col w-full h-full overflow-hidden gap-5 flex-1">
        <NavBar
          v-if="!addEntityWorkflow"
          :timeline="timelineData"
          @back="onBack"
          @cancel="onCancel"
        />

        <EntityBar
          v-if="!addEntityWorkflow"
          :loading="formStates.loading || loaders.redirection"
          :items="computedEntitiesList"
          :readonly="currentStepIndex > 1"
          :plus-disabled="!isAddAvailable"
          @remove="removeEntity"
          @select="onSelectEntity($event.id)"
          @add="showAddEntity"
          :handleEntityChangeStatus="handleEntityChangeStatus"
        />
        <div class="case-creation__body flex flex-col flex-1 w-full h-full overflow-hidden gap-5">
          <div
            v-if="loaders.entity || loaders.step"
            class="p-10 flex items-center justify-center w-full"
          >
            <Loader />
          </div>
          <template v-else>
            <template
              v-if="checkCurrentStep(CASE_CREATION_STEPS.ENTITY_DETAILS)"
            >
              <FormBuilder
                ref="case-creation-form-builder"
                v-if="selectedEntity?.form?.formData && !showEntityChangeNav && !redirectionScreen"
                :data="selectedEntity?.form?.formData"
                :saveSectionMethod="saveEntitySection"
                :formStates="formStates"
                :case_id="tempCaseId"
                :actions-names="formBuilderActionsNames"
                :entity-id="selectedEntity?.id"
                :app="addEntityWorkflow ? app : appList.CREATE "
                @submit="submitEntityForm"
              />

              <div
                v-else-if="loaders.redirection && !redirectionScreen"
                class="p-10 flex items-center justify-center w-full"
              >
                <Loader />
              </div>
              <RedirectionScreen
                v-else-if="redirectionScreen?.redirection_config"
                :screenData="redirectionScreen.redirection_config"
                :case-id="tempCaseId"
                :entity-id="selectedEntity?.id"
                :case-entities="caseEntities"
                :case-entities-data="caseEntitiesData"
                :entity-checks="currentEntityCheckList"
                :loaders="redirectionScreen.loaders"
                @submit="sumbitRedirectionScreen(redirectionScreen)"
                @error="onRedirectionScreenError($event, redirectionScreen)"
              />

              <EntityChangeNav
                v-else-if="showEntityChangeNav"
                class="flex-1"
                :add-options="entitiesToAdd"
                :allow-next="allowNextForm"
                :allow-submit="allowSubmitForm"
                @add="addEntity"
                @next="selectNextEntity"
                @submit="nextStep"
              />
            </template>

            <RedirectionPopup
              v-if="redirectionPopup"
              @submit="sumbitRedirectionScreen(redirectionPopup)"
              @cancel="cancelRedirectionScreen(redirectionPopup)"
              @error="onRedirectionScreenError($event, redirectionPopup)"
            />
          </template>
        </div>
      </div>
    </template>
    <ModalConfirm ref="confirm-popup" />
    <ModalConfirm ref="finish-popup">
      <template #content>
        <ul class="submit-confirmation-text">
          <li>{{ $t('case_creation.sure_added_all') }}</li>
        </ul>
      </template>
    </ModalConfirm>

    <CaseScoreBreakdown
      v-if="caseEntities.length > 1"
      :caseTypeEntityId="caseTypeEntityId"
      :caseEntities="caseEntities"
    />
  </div>
</template>

<script>
// * Currently, we use only one step for Case Creation Flow. All behavior is managed by redirections
// * For now, you can find removed code at "./backup". It will be removed later
// TODO check if non-first step/entity may be selected initially

import axios from "@/axios";
import { mapMutations, mapActions, mapGetters } from "vuex";
import ModalConfirm from "@shared/modal-confirm/index.vue";
import Loader from "@shared/loader/index.vue";
import FormBuilder from "@shared/components/form-builder";
import {
  NavBar,
  PackageList,
  EntityChangeNav,
  CaseScoreBreakdown,
} from "./components";
import { EntityBar } from "@shared/case-entities";
import { Screen, Popup } from "@shared/RedirectionScreen";

import {
  EntityForm,
  getEntitiesCounter,
  getEntitiesToAdd,
} from "./utils";
import {
  checkPermission,
  getShortEntityData,
  getEntityTypeName, 
  getEntityStatuses
} from "@shared/utils/functions";
import {
  CASE_CREATION_STEPS,
  CASE_CREATION_STEPS_ENTITY_FLOW,
} from "./utils/constants.js";
import { appList } from '@shared/components/form-builder/utils/index.js';
import {
  subScreenList,
  redirectionDisplayVariants,
} from "@shared/RedirectionScreen/utils";
import {
  getRedirectionDisplay,
  getCurrentScreenSettings,
} from "@shared/RedirectionScreen/utils/functions";
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";
import handleError from "@shared/mixins/handleError.js";
import {
  fetchEntityForm,
  saveEntityFormSection,
  submitEntityForm,
} from "@shared/case-creation/CaseCreationFlow/utils/entity-form-api.js";

import {
  prefillFormPreview
} from "@shared/case-entities/utils";
import { PermissionError } from "@shared/utils/permissions/index.js";

export default {
  name: "case-creation-flow",
  components: {
    NavBar,
    EntityBar,
    PackageList,
    Loader,
    FormBuilder,
    EntityChangeNav,
    ModalConfirm,
    RedirectionScreen: Screen,
    RedirectionPopup: Popup,
    CaseScoreBreakdown,
  },
  mixins: [handleError],
  props: {
    tenantId: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
      required: true,
    },
    // is used when packages are loading:
    loading: {
      type: Boolean,
      default: false,
    },
    preselectedPackageId: {
      type: String,
    },
    packageList: {
      type: Array,
      default: () => [],
    },
    // is true for not a case creation flow
    addEntityWorkflow: {
      type: Boolean,
      default: false,
    },
    addEntityWorkflowOptions: {
      type: Object,
      default: () => ({
        silentEntityAddition: false
      })
    },
  },
  inject: {
    app: "currentApp",
    isCurrentAppAllowed: "isCurrentAppAllowed",
  },
  provide () {
    return {
      sharedCaseState: () => this.sharedCaseState,
    }
  },
  data() {
    return {
      appList,
      redirectionDisplayVariants,
      isLoading: false, // global loader for this component
      CASE_CREATION_STEPS,
      isCaseSubmitted: false, // use to trigger submitCaseCallback after all actions are finished
      selectedPackageId: null,
      tempCaseId: null,
      caseStatus: null,
      caseTypeEntityId: null,
      caseEntities: [],
      caseEntitiesData: null,
      caseEntityForms: {},
      caseEntityChecks: {},
      selectedEntityId: null,
      loaders: {
        case: false,
        caseSubmission: false,
        form: false, // use to set loading status for the current form
        redirection: false, // use for redirection config fetching
        entity: false,
        step: false,
      },
      addEntityLoaders: {},
      formStates: {
        loading: false,
        submitted: false,
      },
      packageLoaders: {},
      showEntityChangeNav: false, // forces Entity Change Nav show
      isShowAddEntityOnly: false, // is only for entities adding
      currentCaseCreationStep: null,
      formBuilderActionsNames: {
        submit:  'Save',
        preview: 'Preview'
      },
      redirectionConfig: null,
      label: [],
      progress:false,
      progressBarLoading:{
        loading:false
      },
      createtionProgressBar: false,
      permissionErrors: [], // collect to not repeat same errors display
    };
  },

  computed: {
    ...mapGetters({
      getRiskScoreData: "CaseCreationStore/getRiskScoreData",
      getNewEntityInfo: "getNewEntityInfo",
    }),
    singleTypeCaseEntity() {
      return this.entitiesCounter.entities.length === 1 ? this.entitiesCounter.entities[0] : null;
    },

    sharedCaseState() {
      return {
        case_id: this.tempCaseId,
        tenant_id: this.tenantId,
        client_id: this.clientId,
        package_id: this.selectedPackageId,
        selected_entity_id: this.selectedEntityId,
        selected_entity: this.selectedEntity,
        redirection_config: this.redirectionConfig,
        current_workflow_object_id: this.currentWorkflowObjectId,
        is_case_submitted: this.isCaseSubmitted,
        case_entities: this.caseEntities,
        case_entities_data: this.caseEntitiesData,
      }
    },

    caseEntitiesInfo() {
      return this.caseEntitiesData?.case_entity_info || null;
    },

    caseEntityTypesInfo() {
      return this.caseEntitiesData?.case_entity_type_info || null;
    },

    headerItemsConfig() {
      if (!this.computedCaseData) return null;

      // TODO reference to the case score data
      const scoreValue = {
        value: this.getRiskScoreData?.score || 0,
        label: this.getRiskScoreData?.label_name || "",
        color: this.getRiskScoreData?.label_color || "",
        type: 'score',
      }

      let statusValue = null;
      const statusData = this.computedCaseData.status;
      if (statusData) {
        const currentCaseStatus = this.getCurrentStatus(statusData);
        statusValue = {
          value: currentCaseStatus.status_id,
          label: currentCaseStatus.status_name,
          color: currentCaseStatus.status_colour,
        }
      }

      return {
        name: {
          id: "case_id",
          title: this.$t('case_creation.id'),
          value: this.computedCaseData?.name,
          type: "text",
        },
        status: {
          id: "status",
          title: this.$t('case_creation.status'),
          value: statusValue,
          type: "menu",
        },
        score: {
          id: "score",
          title: this.$t('case_creation.risk'),
          value: scoreValue,
          type: "menu",
          show: this.isRiskManagementEnabled,
          listeners: {
            open: () => {
              this.toggleRiskScoreModal();
            }
          },
          props: {
            dropdown: false,
          },
        },
      }
    },
    headerConfig() {
      if (!this.headerItemsConfig) return null;

      const headerItems = Object.values(this.headerItemsConfig)
        .filter((el) => !!el?.value)
        .filter((el) => el.show !== false);

      return {
        title: null,
        list: Object.values(headerItems),
      }
    },
    showCaseCreation() {
      return (
        this.selectedPackage &&
        !this.packageLoaders[this.selectedPackageId] &&
        this.computedCaseData
      );
    },
    selectedPackage() {
      return this.selectedPackageId
        ? this.packageList.find((el) => el.id === this.selectedPackageId)
        : null;
    },
    currentStepIndex() {
      // use positive value
      if (!this.currentCaseCreationStep) return 1;
      return (
        this.caseCreationSteps.findIndex(
          (el) => el.id === this.currentCaseCreationStep.id
        ) + 1
      );
    },
    // provide timeline data if needed:
    timelineData() {
      return null;
    },
    caseEntity() {
      // find an entity with the case temp data:
      return (
        this.caseEntities?.find((el) => el.id === this.caseTypeEntityId) || null
      );
    },
    computedCaseData() {
      if (!this.caseEntity) return null;
      return {
        caseEntity: this.caseEntity,
        name: this.caseEntity.entity_name,
        status: this.caseStatus,
      };
    },
    caseCreationSteps() {
      return CASE_CREATION_STEPS_ENTITY_FLOW;
    },
    // editable entities
    computedEntitiesList() {
      if (!this.caseEntity || !this.caseEntities) return null;

      const isEntitySelectable = (el) => this.currentCaseCreationStep.id !== CASE_CREATION_STEPS.ENTITY_DETAILS
        || (this.selectedEntityId !== el.id
        || this.showEntityChangeNav);

      return this.caseEntities.map((el) => {
        return {
          data: el,
          monitoringData: getShortEntityData(this.caseEntitiesData, el), // add data necessary for monitoring tools
          id: el.id,
          collect_data: el.collect_data,
          type: this.caseEntityTypesInfo[el.entity_type_id]?.name,
          name: el.entity_name || "",
          readonly: el.id === this.caseTypeEntityId,
          disabled: false,
          selectable: isEntitySelectable(el),
          deletable: false,
          selected: (el.id === this.selectedEntityId) && !this.isShowAddEntityOnly, // if we force Add Entity screen display don't show entities as selected
          form: this.caseEntityForms[el.id] || null,
          checks: this.caseEntityChecks[el.id] || null,
          checked: false, // mark with check
          warning: false, // highlight as warning
          statusList: el.statusList
        };
      });
    },
    selectedEntity() {
      if (!this.selectedEntityId || !this.computedEntitiesList) return null;
      return (
        this.computedEntitiesList.find(
          (el) => el.id === this.selectedEntityId
        ) || null
      );
    },
    entitiesCounter() {
      // calculate dynamic counts considering newly created entities
      if (!this.caseEntitiesData || !this.caseEntities) return null;

      return getEntitiesCounter(this.caseEntitiesData, this.caseEntities);
    },
    isAllFormsSubmitted() {
      return !Object.values(this.caseEntityForms).some((el) => !el.isSubmitted);
    },
    isNextEntity() {
      const index = this.getNextEntityIndex();
      return !!index;
    },
    isNextStep() {
      // steps numbering starts from 1 so it already contains +1 when we change the selected step:
      return !!this.caseCreationSteps[this.currentStepIndex];
    },
    allowNextForm() {
      if (!this.entitiesCounter?.entities) return false;
      return (
        this.isNextEntity &&
        this.caseEntityForms?.[this.selectedEntityId]?.isSubmitted &&
        !this.isShowAddEntityOnly // don't show "next" when we open a screen intended only for the new entity adding (click on "Plus" button)
      );
    },
    allowSubmitForm() {
      // unlike the "next" button, this one can be displayed regardless of the call source
      if (!this.entitiesCounter?.entities || !!this.allowNextForm || this.addEntityWorkflow) return false;
      return (
        this.isAllFormsSubmitted &&
        !Object.values(this.entitiesCounter.entities).some(
          (el) => el.count < el.min
        )
      );
    },
    entitiesToAdd() {
      if (!this.entitiesCounter?.entities) return [];

      const entitiesToAdd = getEntitiesToAdd(this.entitiesCounter);
      return entitiesToAdd.map((el) => ({
        ...el,
        loading: this.addEntityLoaders[el.id] || false,
      }));
    },
    isAddAvailable() {
      return !!this.entitiesToAdd.length && this.currentCaseCreationStep.id === CASE_CREATION_STEPS.ENTITY_DETAILS;
    },
    currentEntityCheckList() {
      return this.caseEntityChecks[this.selectedEntityId] || [];
    },

    isCaseEntitySelected() {
      return this.isCaseEntity(this.selectedEntityId);
    },

    isRiskManagementEnabled() {
      return checkPermission('caseRiskScore.read');
    },

    redirectionScreen() {
      return this.redirectionConfig?.[redirectionDisplayVariants.SCREEN] ?? null;
    },
    redirectionPopup() {
      return this.redirectionConfig?.[redirectionDisplayVariants.POPUP] ?? null;
    },
    // check if any redirection screen is shown
    isRedirection() {
      if (!this.redirectionConfig) return false;

      return Object.values(this.redirectionConfig).some(Boolean); 
    },
    currentWorkflowObjectId() {
      return (this.isCaseSubmitted ? this.tempCaseId : this.selectedEntityId) ?? null;
    },
  },
  async mounted() {
    const packageid = this.preselectedPackageId;
    this.resetPackage(packageid || null);

    if (this.preselectedPackageId) {
      // show a loader while fetching temp data for initially selected package
      // because loader of a particular package card isn't shown
      this.isLoading = true;
      await this.selectPackage(this.preselectedPackageId);
      this.isLoading = false;

      if (this.$route.query.entityId && !this.addEntityWorkflow) {
        await this.onSelectEntity(this.$route.query.entityId);
        await this.onEntityFormSubmitted();
      }

      if (this.addEntityWorkflow) {
        this.showEntityChangeNav = !this.addEntityWorkflowOptions.silentEntityAddition;
      }
    }
  },
  created() {
    this.resetCreationStep();
  },
  beforeDestroy() {
      // reset header
      this.setHeaderData(null);
  },
  watch: {
    showEntityChangeNav(val) {
      // reset on Entity Change Nav close
      if (!val) this.isShowAddEntityOnly = false;
    },
    // update header data on package data change:
    headerConfig: {
      handler() {
        this.setHeaderData(this.headerConfig);
      },
      deep: true,
    },
    // update header data on package data change:
    headerItemsConfig: {
      handler() {
        this.setHeaderData(this.headerConfig);
      },
      deep: true,
    },
    // TODO: Temporary solution, may change in the future
    caseEntities: {
      async handler(val) {
        if (val?.length > 1) {
          await this.fetchRiskScore({ case_id: this.tempCaseId });
          await this.fetchCaseStatus();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchRiskScoreMethod: "CaseCreationStore/fetchRiskScore",
      toggleRiskScoreModal: "CaseCreationStore/toggleRiskScoreModal",
      fetchCaseData: "fetchCaseData",
      setSelectedEntity: "setSelectedEntity",
      resetNewEntityInfo: "resetNewEntityInfo"
    }),
    ...mapMutations({
      SET_RISK_SCORE_DATA: "CaseCreationStore/SET_RISK_SCORE_DATA",
      SET_CUSTOM_HEADER: "SET_CUSTOM_HEADER",
      SET_SELECTED_ENTITY: "SET_SELECTED_ENTITY",
    }),

    getEntityTypeName(entity) {
      if (!this.caseEntityTypesInfo) return null;
      return this.caseEntityTypesInfo[entity.entity_type_id].name;
    },

    async handleEntityChangeStatus(payload) {
        const { entity: { id }, status: { status_id } } = payload;
        try {
            const url = `workflow/${id}/status/${status_id}`;
            await axios.post(url);

            // TODO refetch single changed entity status instead full data reset:
            await this.getCaseEntityData();
            this.$toast.success(this.$t('case_creation.entity_status_changed'));
            // await this.showProgressBar(id)
        } catch (error) {
            console.log("error handleEntityChangeStatus :>> ", error);
        }
    },

    // populate header with the current case data:
    setHeaderData(payload = this.headerConfig) {
      if (!this.addEntityWorkflow) this.SET_CUSTOM_HEADER(payload);
    },

    checkCurrentStep(stepId) {
      return this.currentCaseCreationStep.id === stepId;
    },

    async onBack() {
      // currentStepIndex starts from 1 so we need to go to the -2 index in array
      if (this.currentStepIndex > 1) {
        await this.selectStep(
          this.caseCreationSteps[this.currentStepIndex - 2], 
          this.caseEntities[this.caseEntities.length - 1]?.id
        );
      } else {
        this.exitCaseCreation();
      }
    },
  
    async onCancel() {
      try {
        const cancelConfirmed = await this.$refs["confirm-popup"].show({
          title: "Are you sure?",
          message: "The case data will be lost.",
        });
        if (!cancelConfirmed) return;
        await this.deleteTempCaseData();
        this.exitCaseCreation();
        // this.createtionProgressBar = false
      } catch (error) {
        this.handleError(error);
      }
    },

    getNotSubmittedForm() {
        let id;
        for (const key in this.caseEntityForms) {
          if (!this.caseEntityForms[key].isSubmitted) {
            id = key;
            break;
          }
        }
        return id;
    },

    async showAddEntity(payload = true) {
      //const isNewAndNotSubmitted = !this.caseEntityForms[this.selectedEntityId].isSubmitted;
      const notSubmittedId = this.getNotSubmittedForm();
      // if the form is new (not filled => invalid) or changed (dirty):
      if (notSubmittedId) {
          this.$toast.error(this.$t('case_creation.please_submit_form'));

          if (notSubmittedId != this.selectedEntityId) {
            await this.selectEntity(notSubmittedId);
          }
          return;
      }

      // const isSwitchable = this.checkSwitchable(notSubmittedId);
      // if (!isSwitchable) return;
      this.showEntityChangeNav = payload;
      this.isShowAddEntityOnly = payload;
      this.selectedEntityId = null;
      this.setRedirectionConfig();
    },
    async deleteTempCaseData() {
      await axios.delete(`/case/${this.tempCaseId}`);
    },

    async selectStep(step, enityId) {
      // are called after the current step is changed to the new value
      const initStepMethods = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          const entityToSelectId = enityId ||
            (this.caseEntities.find((el) => el.active) || this.caseEntities[0])
              ?.id || null;
          if (entityToSelectId) await this.selectEntity(entityToSelectId);
          // await this.fetchRiskScore({ case_id: this.tempCaseId });
        },
      };

      // reset redirection config:
      this.setRedirectionConfig();

      this.currentCaseCreationStep = step;

      if (step) {
        const { id } = step;
        try {
          await initStepMethods[id]?.();
        } catch (error) {
          this.handleError(error);
        }
      } else 
        this.$emit("redirectToInput", false)
    },

    async nextStep() {
      // are called before the current step is changed to the new value
      const stepCallbacks = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          await this.submitCase();
          return false;
        },
      };

      // manage next step selection:
      let toProceed = true;
      const { id } = this.currentCaseCreationStep;
      try {
        toProceed = await stepCallbacks[id]?.() ?? true;
      } catch (error) {
        this.handleError(error);
      }
      if (!toProceed) return;

      // steps numbering starts from 1 so it already contains +1 when we change the selected step:
      await this.selectStep(this.caseCreationSteps[this.currentStepIndex]);
    },

    resetPackage(payload = null) {
      this.selectedPackageId = payload;
      this.currentCaseCreationStep = this.caseCreationSteps[0];
      this.caseEntitiesOrder = null;
      this.resetCaseCreation();
      // reset header:
      this.setHeaderData(null);

      // if (!payload) {
      //   this.$emit("cancel");
      // }
    },

    resetCaseCreation() {
      this.setSelectedEntity(null);
      this.tempCaseId = null;
      this.caseEntitiesData = null;
      this.caseEntities = [];
      this.caseEntityForms = {};
      this.caseEntityChecks = {};
      this.selectedEntityId = null;
      this.showEntityChangeNav = false;
      this.isCaseSubmitted = false;
      this.permissionErrors = [];
      this.SET_RISK_SCORE_DATA(null);
      // reset redirection config:
      this.setRedirectionConfig();
    },

    resetCreationStep() {
      this.currentCaseCreationStep = this.caseCreationSteps[0];
    },

    exitCaseCreation() {
      this.resetPackage();
      this.$emit("cancel");
      this.SET_RISK_SCORE_DATA(null);
    },

    async initCaseCreationProcess() {
      await this.selectStep(this.caseCreationSteps[0]);
    },

    async selectPackage(packageId) {
      this.selectedPackageId = packageId;
      this.$set(this.packageLoaders, packageId, true);

      try {
        this.resetCreationStep();

        if (this.addEntityWorkflow) {
          await this.addEntityToCase();
        } else {
          this.$router.push({
            query: { ...this.$route.query, packageid: packageId },
          });
          await this.createTempCase();
          await this.fetchRiskScore({ case_id: this.tempCaseId });
        }
        this.$set(this.packageLoaders, packageId, false);
        this.$emit("select:package", packageId);

        await this.initCaseCreationProcess();
      } catch (error) {
        this.handleError(error);
        this.resetPackage();
        this.$set(this.packageLoaders, packageId, false);
        if(this.app === appList.CLIENT)
        {
          console.log("calll")
          this.$router.push(this.$route.query?.from || '/');
        }
      }
    },

    checkSwitchable(showMessage = true) {
      // TODO check if switch should be prevented if !!this.redirectionConfig
      const formBuilder = this.$refs?.["case-creation-form-builder"];
      if (!formBuilder) return true;
      const isDirty = formBuilder.isDirty;
      const isInvalid = formBuilder.$v.$invalid;
      // if the form is new (not filled => invalid) or changed (dirty):
      const isSwitchable = !(isDirty || isInvalid);
      if (!isSwitchable && showMessage)
        this.$toast.error(this.$t('case_creation.save_section_data'));
      return isSwitchable;
    },

    async onSelectEntity(id) {
      const canChangeTab = this.checkSwitchable(this.selectedEntity);
      if (!canChangeTab) return;
      await this.selectEntity(id, { hideEntityChangeNav: true, submit: false, event: 'select-entity' });
      // await this.showProgressBar(id);
    },

    async selectEntity(id, { hideEntityChangeNav = true, submit = false, event } = {}) {
      // return callback to call it with the newly selected Entity:
      const initEntityMethods = {
        [CASE_CREATION_STEPS.ENTITY_DETAILS]: async () => {
          await this.fetchEntityForm(id, { submit, event });
          const checkData = await this.fetchEntityChecks(id);
          const checkList = checkData?.data;
          this.$set(this.caseEntityChecks, id, checkList ?? []);
        },
      };

      // reset redirection config:
      this.setRedirectionConfig();

      if (!id) this.selectedEntityId = null;
      else {
        this.selectedEntityId = id;

        // set selected entity globally:
        this.storeSelectedEntityGlobally();

        this.loaders.entity = true;
        try {
          const entityCreationCallback = await initEntityMethods[this.currentCaseCreationStep.id]?.();

          if (this.selectedEntity) {
            await entityCreationCallback?.(this.selectedEntity);

            // reset errors list to show permission errors again for new entity:
            this.permissionErrors = [];
          }
        } catch (error) {
          this.handleError(error);
        }
        this.loaders.entity = false;
      }
      if (hideEntityChangeNav) {
        this.showEntityChangeNav = false;
      }

      if (event !== 'select-entity' && event != 'add-entity') {
        await this.handleEntityChangeNav(this.selectedEntityId, 'selectEntity');
      }
    },

    setCaseEntitiesOrder(case_entities) {
      if (!this.caseEntitiesOrder) {
        const caseEntityIndex = case_entities.findIndex(
          (el) => el.id === this.caseTypeEntityId
        );
        const resultList = [...case_entities];
        const caseEntity = resultList.splice(caseEntityIndex, 1);
        resultList.unshift(...caseEntity);
        this.caseEntitiesOrder = resultList.map((i) => i.id);
      } else {
        case_entities.forEach((i) => {
          if (!this.caseEntitiesOrder.includes(i.id)) {
            this.caseEntitiesOrder.push(i.id);
          }
        });
      }
      return case_entities.reduce((acc, v) => {
        const index = this.caseEntitiesOrder.findIndex((x) => x == v.id);
        acc[index] = v;
        return acc;
      }, []);
    },

  /**
   * Hadle entities data
   *
   * @param {Object} payload entities data.
   * @param {Boolean} init is called while initial data creation
   */
    storeEntityData(payload, init = false) {
      const { case_entities } = payload;
      this.caseEntities = this.setCaseEntitiesOrder(case_entities);
      this.caseEntitiesData = payload;
      if (init) {
        // create empty forms to start monitoring their states:
        this.caseEntities.forEach((el) => {
          const entity = new EntityForm();
          entity.submit(el.creation_form_submitted); // all entities received on init have already submitted forms
          // TODO we should handle valid/invalid form status to determin if the user must save it before moving to the next step
          this.$set(this.caseEntityForms, el.id, entity);
        })
      }
    },

    async getCaseEntityData(init = false) {
      const { data } = await axios(`case/${this.tempCaseId}/entity`);
      const promises = data.case_entities
          .filter(entity=> getEntityTypeName(data, entity) != ENTITY_TYPE_NAMES.CASE)
          .map(entity => getEntityStatuses(entity.id));
  
      const entityStatuses = await Promise.all(promises);

      data.case_entities = data.case_entities.map(el => {
          el.statusList = entityStatuses.find(x => x.id == el.id)?.data || []; 
          return el;
      })          

      for (const key in data.case_entity_type_info) {
        if (data.case_entity_type_info[key].name === ENTITY_TYPE_NAMES.CASE) {
          const entity = data.case_entities.find((ent) => ent.entity_type_id === key)
          this.caseTypeEntityId = entity.id;
          break;
        }
      }
      await this.storeEntityData(data, init);
    },

    // create case initial data
    async createTempCase() {
      this.tempCaseId = this.$route.query?.caseid;
      if (!this.tempCaseId) {
          const payload = {
            package_id: this.selectedPackageId,
            client_id: this.clientId,
          };
          const { data } = await axios.post(
            `case/create-multientity-case`,
            payload
          );
          // there are different ways to determine case id so we set it separately:
          this.tempCaseId = data?.id;

          this.$router.push({
                query: { ...this.$route.query, caseid: this.tempCaseId },
          });
      }
      
      const promises = ([
        this.fetchCaseStatus(),
        this.fetchCaseData(this.tempCaseId), // TODO can be removed if getCaseData no longer used for the Check Output screens
        this.getCaseEntityData(true),
      ]);

      await Promise.all(promises);
      
      // currently, isn't used
      // this.caseTypeEntityId = data.case_type_entity_id;
      // this.storeEntityData(data.entity_info, true);
    },

    async fetchStatus(id = this.tempCaseId) {
      // fetch entity's status list:
      const { data } = await getEntityStatuses(id);

      // update entity status:
      const relatedEntity = this.caseEntities.find((el) => el.id === id);
      if (relatedEntity)
        relatedEntity.statusList = data;

      return data;
    },

    getCurrentStatus(statusList) {
      return statusList.find((el) => el.current) ?? null;
    },

    async fetchCaseStatus() {
      try {
        this.caseStatus = await this.fetchStatus();
      } catch (error) {
        this.handleError(error, false);
        this.caseStatus = null;
      }
    },

    async addEntityToCase() {
      this.tempCaseId = this.$route.query.caseid;
      await this.getCaseEntityData();
    },

    async fetchEntityForm(case_entity_id, { submit, event }) {
      let data = await fetchEntityForm({
        case_id: this.tempCaseId,
        entity_id: case_entity_id,
      });

      if (event == 'silentEntityAddition' && this.getNewEntityInfo?.entity_name) {
        data = prefillFormPreview(
          data,
          this.getNewEntityInfo.entity_name
        );
        this.resetNewEntityInfo();
      } 

      // fill form of the entity which will be selected:
      this.caseEntityForms[case_entity_id]?.populateForm(data);

      // need to submit fetched entity when after entity was removed
      if (submit) this.selectedEntity?.form?.submit()
    },

    /**
     * Save FormBuilder section method
     *
     * @param {String} section_id
     * @param {Object} data // saved section data
     * @param {Boolean} finalSubmit
     */
    async saveEntitySection(payload) {
      this.formStates.loading = true;
      let sectionSaved = false;
      try {
        sectionSaved = await saveEntityFormSection({
          case_id: this.tempCaseId,
          entity_id: this.selectedEntity.id,
          data: payload,
        });

        const { sections: entityFormSections } = this.selectedEntity.form.formData;
        const updatedSection = entityFormSections.find((el) => el.section_id === payload.section_id);

        // Update entity's form with new values from FormBuilder:
        if (updatedSection) {
          Object.assign(updatedSection, payload.data);
        }
      } catch (error) {
        this.handleError(error);
        sectionSaved = false;
      }
      this.formStates.loading = false;
      return sectionSaved;
    },

    isCaseEntity(id) {
      return id === this.caseEntity.id;
    },

    async onEntityFormSubmitted() {
      // errors are handler on a higher level

      // skip the "Case" entity
      const toFetchWorkflow = !this.isCaseEntitySelected;
      if (toFetchWorkflow) {
        this.loaders.redirection = true;
      }

      // reset redirection config:
      this.setRedirectionConfig();

      this.selectedEntity?.form?.submit();

      
      await this.getCaseEntityData(); 

      // reset globally stored entity:
      this.storeSelectedEntityGlobally();

      // fetch redirection config
      if (toFetchWorkflow) {
        await this.fetchEntityWorkflow();
      } 
      
      await this.handleEntityChangeNav(this.selectedEntityId, 'onEntityFormSubmitted');
      // In case we need updated entity form data we should store updated data from the FormBuilder. Currently, all updated data is available until FormBuilder is rerendered
    },

    async submitEntityForm() {
      this.formStates.loading = true;
      try {
        await submitEntityForm({
          case_id: this.tempCaseId,
          entity_id: this.selectedEntity.id,
        });
        this.$emit('submitAddEntity')
        await this.onEntityFormSubmitted();
        this.$router.push({
          query: { ...this.$route.query, entityId: this.selectedEntity.id },
        });
      } catch (error) {
        this.handleError(error);
      }
      this.formStates.loading = false;
    },

    getEntityCtx(id) {
      const { case_entities } = this.caseEntitiesData;
      const entity = case_entities.find(x => x.id == id);

      const formData = this.caseEntityForms?.[id];

      return  {
        isCaseEntity: this.isCaseEntity(id),
        isSubmitted: entity?.creation_form_submitted || false,
        hasForm: !!formData?.formData?.sections?.length || false
      }
    },

    async handleEntityChangeNav(id) {

      if (this.addEntityWorkflow) return;

      const { 
        isCaseEntity,
        isSubmitted, 
        hasForm
      } = this.getEntityCtx(id);

      const processEntityNav = async () => {
        if (this.singleTypeCaseEntity) {
          const { min } = this.singleTypeCaseEntity;

          if (min === 0 || this.entitiesCounter.total.count > min) {
            this.showEntityChangeNav = true;
          } else {
            await this.addEntity({ id: this.singleTypeCaseEntity.id })
          }
        } else {
          this.showEntityChangeNav = true;
        }
      }

      if (isCaseEntity) {
        if (!hasForm) { 
          if (!isSubmitted) {
            await this.submitEntityForm();
          } else if (this.entitiesCounter.total.count == 1) {// prevents create entity on page referesh
            await processEntityNav();
          }
        } else if (hasForm  && isSubmitted) {
          await processEntityNav();
        }
      } else if (!isCaseEntity && isSubmitted) {
        if (!this.entitiesToAdd.length && !this.isRedirection) { // case creation exit 
          this.nextStep();
        } else if (this.entitiesToAdd.length && !this.redirectionConfig) { // default non case entity type 
          await processEntityNav();
        }
      }
    },
    async addEntity(payload) {
      const { id, entity_name } = payload; // entity_type_id
      try {
        let currentEntityIndex = this.caseEntities.length - 1;
        
        if (this.selectedEntityId) {
           const selectedEntityIndex = this.caseEntities.findIndex((el) => el.id === this.selectedEntityId)
           if (selectedEntityIndex != -1) currentEntityIndex = selectedEntityIndex;
        }

        this.$set(this.addEntityLoaders, id, true);
        const { data } = await axios.post(`case/entity`, {
          case_id: this.tempCaseId,
          entity_type_id: id,
        });

        const newEntity = {
          ...this.selectedEntity?.data,
          id: data.case_entity_id,
          form_id: data.form_id,
          active: false,
          created_at: null,
          entity_name: entity_name ?? null,
          entity_type_id: id,
          form_status_id: null,
          form_status_updated_at: null,
        };
        // await this.showProgressBar(newEntity?.id)
        delete newEntity.statusList;

        this.caseEntities.splice(currentEntityIndex + 1, 1, newEntity);
        this.$emit("addEntity", {
            id: newEntity.id,
            name: newEntity.entity_name || "",
            entity_type:  this.caseEntityTypesInfo[newEntity.entity_type_id]?.name,
        });

        // create empty form to start monitoring their states:
        const entity = new EntityForm();
        this.$set(this.caseEntityForms, newEntity.id, entity);

        // store entity check list:
        this.$set(this.caseEntityChecks, newEntity.id, []);

        await this.selectEntity(newEntity.id, {
          hideEntityChangeNav: true, 
          submit: false,
          event: this.addEntityWorkflowOptions.silentEntityAddition ? 'silentEntityAddition' : 'add-entity'
        });
        await this.fetchRiskScore({ case_id: this.tempCaseId });
      } catch (error) {
        this.handleError(error);
      }
      this.$set(this.addEntityLoaders, id, false);
    },

    async removeEntity(payload) {
      const { id } = payload; // entity_type_id
      const isCurrent = this.selectedEntityId === id;
      const currentEntityIndex = this.caseEntities.findIndex(
        (el) => el.id === id
      );
      const currentComputedEntityIndex = this.computedEntitiesList.findIndex(
        (el) => el.id === id
      );
      try {
        const removeConfirmed = await this.$refs["confirm-popup"].show({
          title: "Are you sure?",
          message:
            "This entity will be deleted permanently. Please confirm to continue deleting this entity.",
        });
        if (removeConfirmed) {
          await axios.delete(`case/${this.tempCaseId}/entity/${id}`);
          // select previous entity if removed was selected
          if (isCurrent)
            await this.selectEntity(this.caseEntities[currentComputedEntityIndex - 1].id, { hideEntityChangeNav: false, submit: true });
          // remove entity from list:
          this.caseEntities.splice(currentEntityIndex, 1);
          this.caseEntitiesOrder = this.caseEntitiesOrder.filter(x => x!=id);
          // remove related form:
          delete this.caseEntityForms[id];
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    getNextEntityIndex(action) {
      let nextIndex = 0;
      if (action != "stepInited") {
        const currentEntityIndex = this.computedEntitiesList.findIndex(
          (el) => el.id === this.selectedEntityId
        );
        nextIndex = currentEntityIndex + 1;
      }

      let enabledIndex;
      for (let i = nextIndex; i < this.computedEntitiesList.length; i++) {
        if (!this.computedEntitiesList[i].disabled) {
          enabledIndex = i;
          break;
        }
      }
      return enabledIndex;
    },

    async selectNextEntity() {
      const entityIndex = this.getNextEntityIndex();
      if (entityIndex) {
        await this.selectEntity(this.computedEntitiesList[entityIndex].id);
        return;
      }

      const nextStep =
        this.caseCreationSteps.findIndex(
          (x) => x.id == this.currentCaseCreationStep.id
        ) + 1;
      if (nextStep) {
        this.selectStep(this.caseCreationSteps[nextStep], true);
      }
    },

    submitCaseCallback() {
      this.$router.push({ name: "dashboard.view" });
    },

    async submitCase() {
      if (this.isCaseSubmitted) return;

      // use to provide steps callbacks:
      const stepCallbacks = {};

      const { id: stepID } = this.currentCaseCreationStep;

      try {
        await stepCallbacks[stepID]?.();
        await axios.post(`case/${this.tempCaseId}/submit-multientity-case`);

        this.isCaseSubmitted = true;
        // check if there is redirection after submit
        await this.fetchEntityWorkflow();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loaders.caseSubmission = false;
      }
    },

    checkCaseCreationExit() {
      // exit the CaseCreationWorkflow if there is no more redirections in the current workflow (e.g., on the Case Screen page):
      if (this.addEntityWorkflow && !this.isRedirection) {
        this.$emit("redirectToInput", false);
      }
    },

    startCaseCreation() {
      this.currentCaseCreationStep = this.caseCreationSteps[0];
    },

    async fetchEntityWorkflow(payload) {
      // use the case id if work with particular entities has been finished:
      const object_id = payload ?? this.currentWorkflowObjectId;

      try {
        this.loaders.redirection = true;

        const { data } = await axios.get(
          `workflow/next-ui`,
          { params: { object_id } }
        );

        const redirectionData = data?.data;
        const redirectionConfig = redirectionData.redirection_config;

        const currentScreenSettings = getCurrentScreenSettings(redirectionConfig);

        const isScreenConfigured = !!currentScreenSettings;

        const checkId = redirectionData?.redirection_config?.sub_configuration?.integration_source_id;
        
        if (checkId) {
          this.$router.push({
            query: { 
              ...this.$route.query, 
              check_id: this.caseEntityChecks?.[this.selectedEntityId]?.find((chk) => chk.integration_source_id === checkId)?.id,
            },
          })
        }

        if (isScreenConfigured) {
          const displayType = getRedirectionDisplay(redirectionData);
          this.setRedirectionConfig(displayType, redirectionData);
        } else {
          // reset redirection screens if there is no more redirections:
          this.setRedirectionConfig();

          await this.ackRedirectionScreen(redirectionData.ack_id); // fallback
        }
      } catch (error) {
        this.setRedirectionConfig();
        if (error.response?.data?.status !== false) throw error;
        // else there is no redirection/workflow configured for this entity
      } finally {
        this.loaders.redirection = false;

        this.checkCaseCreationExit();

        await this.handleEntityChangeNav(this.selectedEntityId, 'fetchEntityWorkflow');

        if (!this.redirectionConfig && this.isCaseSubmitted) {
          this.submitCaseCallback();
        }
      }
    },

    // acknowledge redirection screen (according to configured workflow to te entity)
    async ackRedirectionScreen(ack_id) {
      if (!ack_id) return;
      await axios.post(
          `workflow/next-ui-ack?ack_id=${ack_id}`,
          // { ack_id }
        );
    },

    setRedirectionConfig(type, payload = null) {
      if (!payload && !type) {
        this.redirectionConfig = null;
        return;

      } else if (type === redirectionDisplayVariants.REDIRECT) {
        const redirectionConfig = payload?.redirection_config?.configuration;
        const redirectionSubConfig = payload?.redirection_config?.sub_configuration;
        const routeParams = subScreenList[redirectionConfig]?.(redirectionSubConfig, this.app);

        if (routeParams) this.$router.push(routeParams);
        return;

      } else if (!this.redirectionConfig) {
        // init redirection config if it was empty:
        this.redirectionConfig = {};
      }

      this.$set(this.redirectionConfig, type, {
        ...payload,
        loaders: {
          submission: false,
        },
        type,
      });
    },

    async cancelRedirectionScreen(redirectionConfig = {}) {
      const displayType = getRedirectionDisplay(redirectionConfig);
      this.setRedirectionConfig(displayType); // reset appropriate redirection config
    },

    async sumbitRedirectionScreen(redirectionConfig = {}) {
      const { ack_id } = redirectionConfig;
      if (!ack_id) return;

      try {
        redirectionConfig.loaders.submission = true;
        await this.ackRedirectionScreen(ack_id);
        // refetch current entity status:
        await this.fetchStatus(this.selectedEntityId);

        const promises = [
          this.fetchEntityWorkflow(), // check if there is another redirection config
          this.fetchCaseStatus(), // refetch case status
          // this.showProgressBar(this.selectedEntity.id)
        ];
        await Promise.all(promises);
      } catch (error) {
        this.handleError(error);
      } finally {
        redirectionConfig.loaders.submission = false;
      }
    },

    async onRedirectionScreenError(error, redirectionConfig = {}) {
      // hide current redirection screen while refetching entity workflow
      this.loaders.redirection = true;

      // store received permission error to not show it again for the same entity:
      if (error instanceof PermissionError && !this.permissionErrors.some((err) => err.permission === error.permission)) {
        this.handleError(error);
        this.permissionErrors.push(error);
      }

      // check if there is another redirection config:
      await this.sumbitRedirectionScreen(redirectionConfig);
      // * acknowledge UI Redirection separately instead of submission method calling if another logic should be implemented

      this.loaders.redirection = false;
    },

    storeSelectedEntityGlobally() {
      this.setSelectedEntity({
        ...this.selectedEntity?.monitoringData,
        ...this.selectedEntity?.data,
        checks: this.selectedEntity?.checks,
      });
    },

    async fetchRiskScore(...args) {
      if (!this.isRiskManagementEnabled) return;
      await this.fetchRiskScoreMethod(...args);
    },

    async fetchEntityChecks(entity_id) {
      let url = `case/${this.tempCaseId}/case_entity/${entity_id}/check`;
      if (this.app === appList.CLIENT) {
        url = `case/${this.tempCaseId}/case_entity/${entity_id}/check/client`;
      }
      const { data } = await axios(url);
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.submit-confirmation-text {
  list-style: disc;
  padding-left: toRem(20px);
}
</style>
